<template>
  <div class="widgetContainer widgetContainer--scrollable accountName">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span
          class="icon-Arrow-big-left cancel"
          @click="drawerBack" />
        <p class="title">
          {{ $t('acc_setup_screen_title') }}
        </p>
        <span
          class="el-icon-close submit"
          @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="personAddress"
        :model="formData">
        <el-form-item
          prop="accountLabel"
          class="is-no-asterisk">
          <h1 class="header-1">
            {{ $t('acc_setup_name_title') }}
          </h1>
          <el-input v-model="formData.label" />
        </el-form-item>

        <div class="accountName__wrapper">
          <div class="sendCardByMail"
               v-if="isSendCardByMailVisible">
            <div class="sendCardByMail__header">
              <h1>{{ $t('acc_setup_cardoption_title') }}</h1>
              <el-switch
                v-model="formData.createPhysicalCard"
                active-color="var(--branding)"
                inactive-color="#dfdfe4" />
            </div>
          </div>

          <div class="accountName__terms">
            <el-checkbox
              v-model="formData.acceptedTerms"
              v-if="isAcceptTermEnabled" />
            <div class="accountName__terms__wrapper">
              <p class="wise-forn__info">
                {{ $t('acc_setup_term_label') }}
                <br>
                <br>
                <a
                  v-if="shiflTerms!== ''"
                  :href="shiflTerms"
                  target="_blank">
                  Shifl capital and partner <br> terms and conditions
                </a>
              </p>
              <div
                class="switchWithText"
                v-if="false">
                <p>{{ $t('acc_setup_agree') }}</p>
                <el-switch
                  v-model="formData.acceptedTerms"
                  active-color="var(--branding)"
                  inactive-color="#dfdfe4" />
              </div>
            </div>
          </div>
        </div>

        <div class="account__disclaimer">
          <p>{{ accountDisclaimerText }}</p>
        </div>

        <div class="">
          <el-button
            type="primary"
            :disabled="isFormValid"
            class="el-button__brand brand"
            @click="submit">
            {{ $t('acc_setup_button') }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import {getBrandConfig, getBrandKey} from '@/utils/env';
import {mapGetters} from 'vuex';
import {accountService} from '@/services/solidfi';

export default {
  name: 'SetupAccountComponent',
  data() {
    return {
      isAcceptTermEnabled: false,
      isSendCardByMailVisible: true,
      formData: {
        label: '',
        createPhysicalCard: false,
        acceptedTerms: false,
        type: 'businessChecking'
        // currency: "USD",
      },
      urls: {
        evolveTerms: '',
        platformTerms: '',
        walletTerms: ''
      }
    };
  },
  computed: {
    ...mapGetters('account', ['getAllAccount']),
    ...mapGetters('user', ['getPersonDetails']),
    isFormValid() {
      if (this.formData.label && this.formData.acceptedTerms) {
        return false;
      } else {
        return true;
      }
    },
    accountDisclaimerText() {
      const key = getBrandKey();
      return this.$t(key !== 'solid' ? (key + '_acc_setup_disclaimer') : 'acc_setup_disclaimer')
    },
  },
  created() {
    const evolveTerms = getBrandConfig('evolveTerms', '');
    const platformTerms = getBrandConfig('platformTerms', '');
    const walletTerms = getBrandConfig('walletTerms', '');
    const shiflTerms = getBrandConfig('shiflTerms', '/legal');
    this.isAcceptTermEnabled = getBrandConfig('isAcceptAccTermsEnabled');
    this.isSendCardByMailVisible = getBrandConfig('isSendCardByMailVisible');
    this.evolveTerms = evolveTerms;
    this.shiflTerms = shiflTerms;
    this.platformTerms = platformTerms;
    this.walletTerms = walletTerms;

    if (!this.isAcceptTermEnabled) {
      this.formData.acceptedTerms = true;
    }
  },
  methods: {
    submit() {
      if (this.isFormValid === false) {
        const loader = this.showLoader();
        let data = this.formData;
        data.type = this.$store.getters['account/getCreateAccountData'].type;
        let payload = {
          businessId: this.$store.getters['account/getCreateAccountData'].businessId,
          ...data
        };
        accountService.create(payload)
          .then(() => {
            this.$notify({
              title: 'Account Created Successfully!',
              message: `${data.label} account created! `,
              type: 'success'
            });
            this.drawerBack()
          }).catch(e => {
            this.apiErrorMessage(e)
          }).finally(() => {
            loader.close()
          })
      }
    },
  }
};
</script>
<style lang="scss">
.accountName {
  &__wrapper {
    border-radius: var(--radius);
    border: 1px solid #edeff1;
    padding: 25px 16px;
    margin-bottom: 25px;
  }

  h1 {
    font-size: 16px;
    font-weight: 500;
    color: var(--branding);
  }

  &__setup-title {
    padding: 5px 0 10px 0;
  }

  .sendCardByMail {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 0 22px 0;
      margin-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
    }
  }

  &__terms {
    color: rgba(#3c3c43, 0.6);
    display: flex;
    line-height: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-checkbox {
      margin: 4px 10px 0px auto;
      align-self: flex-start;

      &__inner {
        border-color: var(--branding);
      }

      .is-checked {
        .el-checkbox__inner {
          background-color: var(--branding-primary);
          border-color: var(--branding-primary);
        }
      }
    }

    a {
      color: rgb(88, 86, 214);
      text-decoration: underline;
    }

    .wise-forn__info {
      line-height: 26px;
      font-size: 17px;
    }
  }

  .el-button {
    width: 100%;
  }

  .switchWithText {
    margin-top: auto;
  }
}

.account__disclaimer {
  margin: 40px 0 20px;

  p {
    color: rgba(60, 60, 67, 0.6);
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }
}
</style>